<template lang="">
  <div
    class="flex items-center justify-between border border-gray-300 p-3 w-36 h-12 font-bold rounded-lg"
  >
    <button
      class="bg-gray-400 text-oWhite rounded-md w-6 h-6"
      @click="decrement"
      @mouseleave="mLeave"
    >
      -
    </button>
    <span>{{ counterValue }}</span>
    <button
      class="bg-gray-900 text-oWhite rounded-md w-6 h-6"
      @click="increment"
      @mouseleave="mLeave"
    >
      +
    </button>
  </div>
</template>
<script>
export default {
  name: 'CounterButton',
  props: {
    maxRange: {
      type: Number,
      required: false,
      default: 0,
    },
    defaultCounterValue: {
      type: Number,
      required: false,
      default: 1,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    counterValue: 1,
    isChanged: false,
  }),
  watch: {
    defaultCounterValue: {
      deep: false,
      immediate: true,
      handler(val) {
        this.counterValue = val
      },
    },
    counterValue: {
      deep: false,
      immediate: true,
      handler(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    increment() {
      if (this.disable) return
      if (this.maxRange > 0) {
        if (this.counterValue >= 1 && this.maxRange > this.counterValue) {
          this.counterValue++
          this.isChanged = true
        }
      } else {
        if (this.counterValue >= 1) {
          this.counterValue++
          this.isChanged = true
        }
      }
    },
    decrement() {
      if (this.disable) return
      if (this.counterValue >= 2) {
        this.counterValue--
        this.isChanged = true
      }
    },
    mLeave() {
      if (this.isChanged) {
        this.$emit('leave-update')
        this.isChanged = false
      }
    },
  },
}
</script>
<style lang=""></style>
