<template lang="">
  <BaseLayout mainClass="bg-gray-100">
    <section class="px-5 md:px-16 lg:px-32 xl:px-40 2xl:px-40 bg-gray-100 ">
      <h1 class="font-semibold text-3xl  md:text-4xl  text-left  py-5 ">
        Questions & Answers
      </h1>
      <!-- Vehicle Ownership -->
      <section>
        <h2 class="text-xl md:text-2xl  font-bold py-5">Vehicle Ownership</h2>
        <div class="rounded-md p-5 bg-oWhite">
          <h3
            class="px-3 border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(11)"
          >
            When I buy a vehicle, is it mine?
          </h3>
          <p
            v-if="activeNumber === 11"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg"
          >
            Yes, you are the sole owner of every vehicle you purchase through
            SOLride.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(12)"
          >
            Does the vehicle leaseback affect my vehicle ownership?
          </h3>
          <p
            v-if="activeNumber === 12"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            No, you keep the vehicle ownership throughout the entire leaseback
            period.
          </p>
        </div>
      </section>
      <!-- Vehicle Leaseback -->
      <section>
        <h2 class="text-xl md:text-2xl  font-bold py-5">Vehicle Leaseback</h2>
        <div class="rounded-md p-5 bg-oWhite">
          <h3
            class="px-3 border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(21)"
          >
            What is a vehicle leaseback?
          </h3>
          <p
            v-if="activeNumber === 21"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg"
          >
            When you purchase a vehicle through SOLride, we lease that vehicle
            from you and operate it on your behalf during the agreed leaseback
            period.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(22)"
          >
            How long is the leaseback period of a new vehicle?
          </h3>
          <p
            v-if="activeNumber === 22"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            A new vehicle's leaseback period is three years starting from its
            street deployment date.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(23)"
          >
            Can I get my vehicle(s) after the leaseback period ends?
          </h3>
          <p
            v-if="activeNumber === 23"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            Yes, you can pick up any vehicle that has not been decommissioned
            during the leaseback period from its storage at your cost.
          </p>
        </div>
      </section>
      <!-- Vehicle Fleets -->
      <section>
        <h2 class="text-xl md:text-2xl  font-bold py-5">Vehicle Fleets</h2>
        <div class="rounded-md p-5 bg-oWhite">
          <h3
            class="px-3 border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(31)"
          >
            Do I have to assign my vehicle(s) to a fleet?
          </h3>
          <p
            v-if="activeNumber === 31"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg"
          >
            Yes, the fleet you select determines in which country your vehicles
            are operated.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(32)"
          >
            How many fleets, brands, and countries can I choose from?
          </h3>
          <p
            v-if="activeNumber === 32"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            Currently, you can choose from one fleet, and country. We notify you
            whenever new fleets, and/or countries become available.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(33)"
          >
            Why are my vehicles added to a fleet?
          </h3>
          <p
            v-if="activeNumber === 33"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            By adding your vehicle(s) to a fleet, the risk of decommissioning
            and losing your vehicle(s) is shared proportionally among all
            vehicle owners in the fleet.
          </p>
        </div>
      </section>
      <!-- Vehicle Operations -->
      <section>
        <h2 class="text-xl md:text-2xl  font-bold py-5">Vehicle Operations</h2>
        <div class="rounded-md p-5 bg-oWhite">
          <h3
            class="px-3 border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(41)"
          >
            Are my vehicles insured?
          </h3>
          <p
            v-if="activeNumber === 41"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg"
          >
            Your vehicle(s) are insured against damage and theft when placed in
            storage but not during street operations.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(42)"
          >
            What happens if one of the vehicles I own is stolen or broken beyond
            repair?
          </h3>
          <p
            v-if="activeNumber === 42"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            In both cases the vehicle is removed from the fleet it is assigned
            to. In this way, the impact is shared equally by the fleet owners.
          </p>
        </div>
      </section>
      <!-- Vehicle Rent Income -->
      <section>
        <h2 class="text-xl md:text-2xl  font-bold py-5">Vehicle Rent Income</h2>
        <div class="rounded-md p-5 bg-oWhite">
          <h3
            class="px-3 border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(51)"
          >
            How quickly do I earn the vehicle purchase price?
          </h3>
          <p
            v-if="activeNumber === 51"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg"
          >
            A good rule of thumb is that you earn the vehicle purchase price
            back in the 1st and 2nd year, and make profit from the 3rd year
            onward.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(52)"
          >
            How is my vehicle rental revenue calculated?
          </h3>
          <p
            v-if="activeNumber === 52"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            Your vehicle rental revenue is calculated based on the number of
            vehicles you own, the total number of vehicles in the fleet, and the
            total revenue generated by the fleet.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(53)"
          >
            How often do I receive my vehicle rental revenue?
          </h3>
          <p
            v-if="activeNumber === 53"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            You can choose between automatic monthly payouts and manual payouts
            that you can request at any time and as often as you prefer.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(54)"
          >
            Can I buy new vehicles from my earned vehicle rent income?
          </h3>
          <p
            v-if="activeNumber === 54"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            Yes, you can use your earnings to purchase additional vehicles.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(55)"
          >
            How do I report vehicle rent income to the country where I'm a tax
            resident?
          </h3>
          <p
            v-if="activeNumber === 55"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            You will have to report the rent income yourself and either book it
            to the bookkeeping of your company or report the income in your
            personal tax filing.
          </p>
        </div>
      </section>
      <!-- Vehicle Secondary Market -->
      <section>
        <h2 class="text-xl md:text-2xl  font-bold py-5">
          Vehicle Secondary Market
        </h2>
        <div class="rounded-md p-5 bg-oWhite">
          <h3
            class="px-3 border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(61)"
          >
            How does a secondary market transaction work?
          </h3>
          <p
            v-if="activeNumber === 61"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg"
          >
            Once the buyer purchases a secondary market vehicle, SOL-ride.io
            will issue an invoice to the buyer and pay the secondary market
            purchase price minus SOL-ride.io’s processing and payment fees to
            the seller upon payment receipt from the buyer.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(62)"
          >
            Is there a limit to how many of my vehicles I can offer for sale on
            the secondary market?
          </h3>
          <p
            v-if="activeNumber === 62"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            No, you can offer all your vehicles for sale on the secondary market
            at once.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(63)"
          >
            Is there any price cap for vehicles sold on the secondary market?
          </h3>
          <p
            v-if="activeNumber === 63"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            Yes, a vehicle can only be sold for 20% above of its depreciation
            value on the secondary market.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(64)"
          >
            Are there any secondary market transaction fees for the buyer?
          </h3>
          <p
            v-if="activeNumber === 64"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            No, the buyer pays only the agreed-upon secondary market purchase
            price and no additional secondary market transaction fees.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(65)"
          >
            Are there any secondary market transaction fees for the seller?
          </h3>
          <p
            v-if="activeNumber === 65"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            Yes, SOL-ride.io charges a 5% processing fee of the secondary market
            purchase price plus a EUR 5 payment fee to the seller.
          </p>
          <hr class="my-2" />

          <h3
            class="px-3  border-l-4 border-gray-700 text-lg md:text-xl font-bold  cursor-pointer"
            @click="setActiveNumber(66)"
          >
            Are all secondary market transactions on SOL-ride.io anonymous?
          </h3>
          <p
            v-if="activeNumber === 66"
            class="px-3 py- border-l-4 border-gray-400 md:text-lg "
          >
            Yes, except for SOL-rode.io as the transaction processor, the
            seller's and buyer's identities always remain undisclosed.
          </p>
        </div>
      </section>
    </section>
    <section class="pt-5">
      <Footer />
    </section>
  </BaseLayout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import Footer from '@/composites/marketplace-buyer/footer/Footer.vue'
export default {
  name: 'MarketplaceFaqs',

  components: {
    BaseLayout,
    Footer,
  },
  data() {
    return {
      activeNumber: 0,
    }
  },
  methods: {
    setActiveNumber(number) {
      this.activeNumber = number
    },
  },
}
</script>
<style lang=""></style>
