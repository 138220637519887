<template lang="">
  <BaseLayout mainClass="bg-oWhite">
    <loading :active.sync="isLoading" :is-full-page="false" />
    <section
      class="flex items-center justify-center h-16 font-bold bg-green-600 text-oWhite"
      :class="getVariantColor"
    >
      <span class="text-lg">{{ getSalesDateTitle }} </span> &nbsp; &nbsp;
      <span class="text-4xl">{{ getSalesDate }}</span>
    </section>
    <section>
      <section
        class="px-5 md:px-16 lg:px-32 xl:px-40 2xl:px-40 py-12 bg-gray-100 "
      >
        <div class="grid grid-cols-12  gap-4">
          <div class="md:col-span-6 col-span-12 flex justify-center">
            <img
              :src="preorderDetails.vehicle_model.vehicle_image"
              alt="vehicle"
              class="w-auto header-img-box rounded-lg"
            />
          </div>
          <div class="md:col-span-6 col-span-12  ">
            <h2 class="text-2xl font-bold pb-3">Overview</h2>
            <div class="flex text-lg p-1">
              <div class="w-5/12 lg:w-1/4">Order ID</div>
              <div class="w-7/12 lg:w-3/4">
                #{{ $truncate(preorderDetails.id, -5) }}
              </div>
            </div>
            <div class="flex text-lg p-1">
              <div class="w-5/12 lg:w-1/4">Order Status</div>
              <div class="w-7/12 lg:w-3/4">
                {{ getItemStatusTitle(preorderDetails.order_status) }}
              </div>
            </div>
            <div class="flex text-lg p-1">
              <div class="w-5/12 lg:w-1/4">Total Vehicles</div>
              <div class="w-7/12 lg:w-3/4">
                {{ preorderDetails.total_vehicle_number }}
              </div>
            </div>

            <div class="flex text-lg p-1">
              <div class="w-5/12 lg:w-1/4">Vehicle Unit Price</div>
              <div class="w-7/12 lg:w-3/4">
                {{ getCurrencySymbol }} {{ preorderDetails.per_unit_price }}
              </div>
            </div>

            <div class="flex text-lg p-1">
              <div class="w-5/12 lg:w-1/4">Brand</div>
              <div class="w-7/12 lg:w-3/4">
                {{ preorderDetails.brand_name }}
              </div>
            </div>
            <div class="flex text-lg p-1">
              <div class="w-5/12 lg:w-1/4">Rent Share</div>
              <div class="w-7/12 lg:w-3/4">
                <p>
                  {{ preorderDetails.rent_share_1st_year_by_percentage }}% (1st
                  year)
                </p>
                <p>
                  {{ preorderDetails.rent_share_2nd_year_by_percentage }}% (2nd
                  year)
                </p>
                <p>
                  {{ preorderDetails.rent_share_3rd_year_by_percentage }}% (3rd
                  year)
                </p>
                <p>
                  {{ preorderDetails.rent_share_4th_year_by_percentage }}% (4th
                  year)
                </p>
              </div>
            </div>
            <div class="flex text-lg p-1 items-center ">
              <div class="w-5/12 lg:w-1/4">
                Order Value
              </div>
              <div class="w-7/12 lg:w-3/4 text-2xl font-semibold">
                {{ getCurrencySymbol }}
                {{ preorderDetails.per_unit_price * orderCount }}
              </div>
            </div>
            <div
              class="flex items-center text-lg p-1"
              v-if="preorderDetails.order_status === 'Started'"
            >
              <div class="w-5/12 lg:w-1/4 ">
                Order Quantity
              </div>
              <div class="w-7/12 lg:w-3/4 ">
                <CounterButton
                  v-if="isOrderable"
                  class="my-2"
                  :maxRange="getLeftVehicles(preorderDetails)"
                  @input="
                    ($event) => {
                      orderCount = $event
                    }
                  "
                />
              </div>
            </div>

            <button
              @click="goPayment"
              class="relative rounded-full w-full bg-color my-border md:w-4/5 font-bold  duration-200  text-lg py-3 px-4 mt-4 "
              :class="getOrderButtonClass"
            >
              <span v-if="isBuyNowLoading">
                <loading
                  :height="25"
                  :color="BRAND_CONFIG.COLOR.PRIMARY_COLOR"
                  :active="isBuyNowLoading"
                  loader="bars"
                  :class="`outline-none`"
                  :is-full-page="false"
                />
                &nbsp;
              </span>
              <span v-else>Buy Now</span>
            </button>
            <div
              class="w-full  md:w-4/5 flex items-center justify-center py-1 mt-2"
            >
              <img
                src="@/assets/marketplace/secure-shield-green.svg"
                alt="secure-shield-green"
              />
              <span class="pl-3">Secure payment</span>
            </div>
          </div>
        </div>
      </section>
      <section
        class="px-5 md:px-16 lg:px-32 xl:px-40 2xl:px-40 py-5 bg-gray-100 rounded-lg"
      >
        <section class="py-5">
          <h2 class="text-2xl md:text-3xl font-bold">Vehicle Overview</h2>
          <div class="grid grid-cols-12 gap-4  mt-10">
            <div
              class="col-span-12 md:col-span-4 flex items-center p-5   border border-gray-200 rounded-lg"
            >
              <div>
                <img src="@/assets/marketplace/speed.svg" alt="Top Speed" />
              </div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Top Speed</p>
                <h3 class="text-2xl font-bold">
                  {{ preorderDetails.vehicle_model.max_speed }}
                  {{ preorderDetails.vehicle_model.max_speed_unit }}
                </h3>
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-4 flex items-center p-5   border border-gray-200 rounded-lg"
            >
              <div>
                <img src="@/assets/marketplace/range.svg" alt="Top Speed" />
              </div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Range</p>
                <h3 class="text-2xl font-bold">
                  {{ preorderDetails.vehicle_model.mileage }}
                  {{ preorderDetails.vehicle_model.mileage_unit }}
                </h3>
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-4 flex items-center p-5   border border-gray-200 rounded-lg"
            >
              <div>
                <img
                  src="@/assets/marketplace/swap-battery.svg"
                  alt="Swappable battery"
                />
              </div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Swappable battery</p>
                <h3 class="text-2xl font-bold">
                  {{
                    preorderDetails.vehicle_model.sweapeable_battery
                      ? 'Yes'
                      : 'No'
                  }}
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section class="py-5">
          <h4 class="text-lg font-medium  mb-2">Description</h4>

          <ul>
            <li><b>Motor:</b> {{ preorderDetails.vehicle_model.motor }}</li>
            <li>
              <b>Battery Capacity:</b>
              {{ preorderDetails.vehicle_model.battery_capacity }}
            </li>
            <li>
              <b>Dimensions:</b> {{ preorderDetails.vehicle_model.dimensions }}
            </li>
            <li><b>Weight:</b> {{ preorderDetails.vehicle_model.weight }}</li>
            <li>
              <b>Load Capacity:</b>
              {{ preorderDetails.vehicle_model.load_capacity }}
            </li>

            <li
              v-for="(item, index) in preorderDetails.vehicle_model.specs"
              :key="`spec-${index}`"
              class="text-lg"
            >
              {{ item }}
            </li>
          </ul>
        </section>
        <section class="py-5">
          <h2 class="text-2xl md:text-3xl font-bold">Manufacture Overview</h2>
          <div
            class="grid grid-cols-12 gap-4  mt-10  border border-gray-200 rounded-lg p-5"
          >
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Legal Company Name</p>
                <h3 class="text-2xl">
                  {{ preorderDetails.lock_manufacturer.name }}
                </h3>
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Founded</p>
                <h3 class="text-2xl">
                  {{
                    getFormattedDateTime(
                      preorderDetails.lock_manufacturer.year_founded,
                      'YYYY'
                    )
                  }}
                </h3>
              </div>
            </div>
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center   "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Company Type</p>
                <h3 class="text-2xl">
                  {{
                    preorderDetails.lock_manufacturer
                      .vehicle_manufacturer_business_type || '--'
                  }}
                </h3>
              </div>
            </div>
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center  "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Company Size</p>
                <h3 class="text-2xl">
                  {{ preorderDetails.lock_manufacturer.company_employee_size }}
                  employees
                </h3>
              </div>
            </div>
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Business ID</p>
                <h3 class="text-2xl">
                  {{
                    preorderDetails.lock_manufacturer
                      .vehicle_manufacturer_business_id || '--'
                  }}
                </h3>
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center  "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Website</p>
                <h3 class="text-2xl">
                  <a
                    :href="preorderDetails.lock_manufacturer.website_url"
                    target="_blank"
                  >
                    {{ preorderDetails.lock_manufacturer.website_url || '--' }}
                  </a>
                </h3>
              </div>
            </div>
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center  "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Headquarters Location</p>
                <h3 class="text-2xl">
                  {{
                    preorderDetails.lock_manufacturer.headquarter_address ||
                      '--'
                  }}
                </h3>
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center   "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Social Media</p>
                <div class="flex gap-4 pt-1">
                  <a
                    v-if="preorderDetails.lock_manufacturer.facebook_url"
                    :href="preorderDetails.lock_manufacturer.facebook_url"
                    target="_blank"
                    ><img
                      class="cursor-pointer"
                      src="@/assets/marketplace/facebook.svg"
                      alt="facebook"
                  /></a>
                  <a
                    v-if="preorderDetails.lock_manufacturer.linkedin_url"
                    :href="preorderDetails.lock_manufacturer.linkedin_url"
                    target="_blank"
                  >
                    <img
                      class="cursor-pointer"
                      src="@/assets/marketplace/linkedin.svg"
                      alt="linkedin"
                    />
                  </a>
                  <a
                    v-if="preorderDetails.lock_manufacturer.twitter_url"
                    :href="preorderDetails.lock_manufacturer.twitter_url"
                    target="_blank"
                  >
                    <img
                      class="cursor-pointer"
                      src="@/assets/marketplace/twitter.svg"
                      alt="twitter"
                    />
                  </a>
                  <a
                    v-if="preorderDetails.lock_manufacturer.instagram_url"
                    :href="preorderDetails.lock_manufacturer.instagram_url"
                    target="_blank"
                  >
                    <img
                      class="cursor-pointer"
                      src="@/assets/marketplace/instagram.svg"
                      alt="instagram"
                    />
                  </a>
                  <a
                    v-if="preorderDetails.lock_manufacturer.youtube_url"
                    :href="preorderDetails.lock_manufacturer.youtube_url"
                    target="_blank"
                  >
                    <img
                      class="cursor-pointer"
                      src="@/assets/marketplace/youtube.svg"
                      alt="youtube"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="py-5">
          <h2 class="text-2xl md:text-3xl font-bold">Fleet Overview</h2>
          <div
            class="grid grid-cols-12 gap-4  mt-10  border border-gray-200 rounded-lg p-5"
          >
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-start "
            >
              <div class="">
                <img
                  :src="preorderDetails.brand_image"
                  alt="vehicle"
                  class="w-44 header-img-box "
                />
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center   "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Legal Company Name</p>
                <h3 class="text-2xl">
                  {{ preorderDetails.brand_name || '--' }}
                </h3>
              </div>
            </div>
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center  "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Operating Country</p>
                <h3 class="text-2xl">
                  {{ preorderDetails.fleet.country.name }}
                </h3>
              </div>
            </div>
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Founded</p>
                <h3 class="text-2xl">
                  {{
                    getFormattedDateTime(preorderDetails.year_founded, 'YYYY')
                  }}
                </h3>
              </div>
            </div>
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Business Model</p>
                <h3 class="text-2xl">
                  {{ getBusinessModel(preorderDetails.business_model) }}
                </h3>
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center  "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Business Id</p>
                <h3 class="text-2xl">
                  {{ preorderDetails.business_id || '--' }}
                </h3>
              </div>
            </div>
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center  "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Headquarters Location</p>
                <h3 class="text-2xl">
                  {{ preorderDetails.fleet_address || '--' }}
                </h3>
              </div>
            </div>
            <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center  "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Sol-ride.io Fleet VUM</p>
                <h3 class="text-2xl">
                  {{ preorderDetails.total_vehicle_number || '--' }}
                </h3>
              </div>
            </div>

            <!-- <div
              class="col-span-12 md:col-span-6 lg:col-span-3 flex items-center   "
            >
              <div class="border-l-2 border-gray-300 py-4"></div>
              <div class="pl-5">
                <p class="text-oGray text-sm">Social Media</p>
                <div class="flex gap-4 pt-1">
                  <a
                    v-if="preorderDetails.facebook_url"
                    :href="preorderDetails.facebook_url"
                    target="_blank"
                    ><img
                      class="cursor-pointer"
                      src="@/assets/marketplace/facebook.svg"
                      alt="facebook"
                  /></a>
                  <a
                    v-if="preorderDetails.linkedin_url"
                    :href="preorderDetails.linkedin_url"
                    target="_blank"
                  >
                    <img
                      class="cursor-pointer"
                      src="@/assets/marketplace/linkedin.svg"
                      alt="linkedin"
                    />
                  </a>

                  <a
                    v-if="preorderDetails.instagram_url"
                    :href="preorderDetails.instagram_url"
                    target="_blank"
                  >
                    <img
                      class="cursor-pointer"
                      src="@/assets/marketplace/instagram.svg"
                      alt="linkedin"
                    />
                  </a>
                  <a
                    v-if="preorderDetails.youtube_url"
                    :href="preorderDetails.youtube_url"
                    target="_blank"
                  >
                    <img
                      class="cursor-pointer"
                      src="@/assets/marketplace/youtube.svg"
                      alt="linkedin"
                    />
                  </a>
                  <a
                    v-if="preorderDetails.ios_app_store_url"
                    :href="preorderDetails.ios_app_store_url"
                    target="_blank"
                  >
                    <img
                      class="cursor-pointer"
                      width="28px"
                      src="@/assets/marketplace/appstore.png"
                      alt="linkedin"
                    />
                  </a>
                  <a
                    v-if="preorderDetails.android_play_store_url"
                    :href="preorderDetails.android_play_store_url"
                    target="_blank"
                  >
                    <img
                      class="cursor-pointer"
                      src="@/assets/marketplace/playstore.svg"
                      alt="linkedin"
                      width="28px"
                    />
                  </a>
                </div>
              </div>
            </div> -->
          </div>
        </section>
      </section>
    </section>
    <section>
      <Footer />
    </section>
  </BaseLayout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import CounterButton from '@/composites/marketplace-buyer/CounterButton.vue'
import Footer from '@/composites/marketplace-buyer/footer/Footer.vue'
import { useEndpoints } from '@/composables'
import { getFormattedDateTime } from '@/utils/datetime'
import { httpClient } from '@/services/httpClient'
import { TYPE, POSITION } from 'vue-toastification'
import { BRAND_CONFIG } from '@/config/brand.config'
export default {
  name: 'MarketplaceOrderDetails',
  components: {
    BaseLayout,
    CounterButton,
    Footer,
  },

  data: () => ({
    isLoading: false,
    isBuyNowLoading: false,
    path: '',
    orderCount: 1,
    preorderDetails: {},
    BRAND_CONFIG: BRAND_CONFIG,
  }),
  created() {
    this.path = this.$route.path
    this.getPreorderDetails()
  },

  computed: {
    id() {
      return this.$route.params.id
    },
    getCurrencySymbol() {
      return this.preorderDetails.fleet.country.currency_symbol
    },
    getVariantColor() {
      if (this.preorderDetails.order_status === 'Started') {
        return 'bg-oGreen'
      } else if (this.preorderDetails.order_status === 'Completed') {
        return 'bg-oGray'
      } else if (this.preorderDetails.order_status === 'Expired') {
        return 'bg-oRed'
      } else {
        return 'bg-oOrange'
      }
    },
    getSalesDateTitle() {
      if (this.preorderDetails.order_status === 'Started') {
        return 'Sales will end on'
      } else if (this.preorderDetails.order_status === 'Completed') {
        return 'Sales completed'
      } else if (this.preorderDetails.order_status === 'Expired') {
        return 'Sales expired at'
      } else {
        return 'Sales will start at'
      }
    },
    getSalesDate() {
      if (this.preorderDetails.order_status === 'Started') {
        return this.getFormattedDateTime(
          this.preorderDetails.order_end_date,
          'DD MMM YYYY'
        )
      } else if (this.preorderDetails.order_status === 'Completed') {
        return ''
      } else if (this.preorderDetails.order_status === 'Expired') {
        return this.getFormattedDateTime(
          this.preorderDetails.order_end_date,
          'DD MMM YYYY'
        )
      } else {
        return this.getFormattedDateTime(
          this.preorderDetails.order_start_date,
          'DD MMM YYYY'
        )
      }
    },
    getOrderButtonClass() {
      if (this.preorderDetails.order_status === 'Started') {
        return 'bg-gray-900 text-oWhite  hover:bg-oWhite hover:text-oBlack  hover:border-gray-900'
      } else {
        return 'bg-gray-300  border-gray-200 hover:bg-gray-300 text-gray-600 hover:text-gray-800'
      }
    },
    isOrderable() {
      if (this.preorderDetails.order_status === 'Started') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    getFormattedDateTime,
    async getPreorderDetails() {
      this.isLoading = true
      await httpClient
        .get(useEndpoints.marketplaceBuyer.preorderDetails(this.id))
        .then((res) => {
          this.preorderDetails = res.data.data
        })
        .finally(() => (this.isLoading = false))
    },
    getItemStatusTitle(data) {
      if (data === 'Started') return 'In Progress'
      if (data === 'Pending') return 'Upcoming'
      return data
    },
    getLeftVehicles(data) {
      return data.total_vehicle_number - data.total_purchased_vehicle
    },
    getBusinessModel(model) {
      if (model === 'SH') {
        return 'Sharing'
      } else {
        return 'Store Rental'
      }
    },
    async goPayment() {
      console.log('aT-', this.$store.getters['auth/accessToken'])
      if (this.$store.getters['auth/accessToken']) {
        if (this.preorderDetails.order_status === 'Started') {
          this.isBuyNowLoading = true
          const orderData = new FormData()
          orderData.append('marketplace_fleet', this.id)
          orderData.append('number_of_vehicles_to_buy', this.orderCount)
          await httpClient
            .post(useEndpoints.marketplaceBuyer.orderCreate(), orderData)
            .then((res) => {
              console.log('create-buyer-order', res)
              this.$router.push(`${this.path}/payment`)
            })
            .catch((error) => {
              this.$toast(`${error}`, {
                position: POSITION.BOTTOM_RIGHT,
                type: TYPE.ERROR,
              })
            })
            .finally(() => (this.isBuyNowLoading = false))
        } else {
          if (this.preorderDetails.order_status === 'Pending') {
            this.$toast(
              `Sales will be start from ${getFormattedDateTime(
                this.preorderDetails.order_start_date,
                'DD MMM YYYY'
              )}`,
              {
                position: POSITION.BOTTOM_RIGHT,
                type: TYPE.WARNING,
              }
            )
          }
          if (this.preorderDetails.order_status === 'Expired') {
            this.$toast(
              `Sales was expired on ${getFormattedDateTime(
                this.preorderDetails.order_end_date,
                'DD MMM YYYY'
              )}`,
              {
                position: POSITION.BOTTOM_RIGHT,
                type: TYPE.WARNING,
              }
            )
          }
        }
      } else {
        localStorage.setItem('route-path', this.$router.currentRoute.path)
        this.$router.push(`/login`)
      }
    },
  },
}
</script>
<style lang="scss">
.header-img-box {
  max-height: 24rem;
}
ul {
  list-style-type: disc;
  padding: 10px 0 !important;
  padding-left: 20px !important;
}

.bg-color {
  background: var(--brand-primary-color);
}
.my-border:hover {
  border: 2px solid var(--brand-primary-color);
  color: var(--brand-primary-color);
}
</style>
