<template lang="">
  <footer
    class="bg-oBlack px-5 md:px-16 lg:px-32 xl:px-40 2xl:px-40 py-5 lg:py-10"
  >
    <div class="grid grid-cols-12 text-oWhite">
      <div class="col-span-12 md:col-span-4 md:flex md:items-center">
        <router-link :to="{ name: 'Home' }">
          <img
            class="image-range py-3"
            :src="BRAND_CONFIG.ORG_LIGHT_LOGO"
            alt="logo"
          />
        </router-link>
      </div>
      <div
        class="col-span-12 md:col-span-1 md:flex md:items-center cursor-pointer"
      >
        <p
          class="text-oGray hover:text-oWhite py-3"
          @click="gotoTermsAndCondition()"
        >
          T&Cs
        </p>
      </div>

      <div class="col-span-12 md:col-span-1 md:flex md:items-center">
        <p
          class="text-oGray hover:text-oWhite py-3 cursor-pointer"
          @click="gotoFAQ()"
        >
          FAQs
        </p>
      </div>
      <div
        class="col-span-12 md:col-span-2 md:flex md:items-center cursor-pointer"
      >
        <p class="text-oGray hover:text-oWhite py-3">
          <a
            :href="
              `mailto:${BRAND_CONFIG.ORG_EMAIL}?subject=Inquiry%20from%20Website&body=Hi,%20I%20would%20like%20to%20get%20in%20touch%20with%20you.%20Please%20let%20me%20know%20how%20I%20can%20contact%20you.%0D%0A%0D%0ABest%20regards,%0D%0A`
            "
            >Get in Touch</a
          >
        </p>
      </div>
      <div
        class="col-span-12 md:col-span-4  md:flex md:items-center  md:justify-end py-3"
      >
        <div>
          <!-- facebook-icon -->
          <a href="https://www.facebook.com/SOLride.io" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="social-icon facebook"
              viewBox="0 0 448 512"
            >
              <path
                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
              />
            </svg>
          </a>

          <!-- twitter-icon -->
          <a href="https://twitter.com/SOL_ride" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="social-icon twitter"
              viewBox="0 0 512 512"
            >
              <path
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              />
            </svg>
          </a>

          <!-- Linkedin-icon -->
          <a href="https://www.linkedin.com/in/solride/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="social-icon linkedin"
              viewBox="0 0 448 512"
            >
              <path
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              />
            </svg>
          </a>
          <!-- Instagram-icon -->

          <a href="https://www.instagram.com/ride_with_sol/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 30"
              class="social-icon instagram"
            >
              <path
                d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { BRAND_CONFIG } from '@/config/brand.config'
export default {
  name: 'Footer',
  methods: {
    gotoFAQ() {
      this.$router.push(`/faq`)
    },
    gotoTermsAndCondition() {
      window.open('/SOLRIDE_T&C.pdf', '_blank')
    },
  },
  data: () => ({
    BRAND_CONFIG: BRAND_CONFIG,
  }),
}
</script>
<style lang="css">
.social-link {
  display: inline-block;
}
.social-icon {
  height: 25px;
  width: 25px;
  fill: #999999;
  margin-right: 30px;
  display: inline-block;
  cursor: pointer;
}

.facebook,
.twitter,
.instagram,
.linkedin {
  transition: 0.2s;
}

.facebook:hover {
  fill: #ffffff;
  transition: 0.2s;
}
.twitter:hover {
  fill: #ffffff;
  transition: 0.2s;
}

.linkedin:hover {
  fill: #ffffff;
  transition: 0.2s;
}
.instagram:hover {
  fill: #ffffff;
  transition: 0.2s;
}
.image-range {
  max-width: 9rem;
}
</style>
